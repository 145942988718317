import { combineReducers } from 'redux';
import Auth from './Auth';
import Theme from './Theme';
import PurchaseOrders from './PurchaseOrders';
import PurchaseOrderFilters from './PurchaseOrderFilters';
import Suppliers from './Suppliers';
import Buyers from './Buyers';
import SearchSupplier from './SearchSupplier';
import StandingPurchaseOrders from './StandingPurchaseOrders';
import SearchPreferredItem from './SearchPreferredItem';
import SalesOnly from './SalesOnly';
import ToggleBOCFilterOptions from './ToggleBOCFilterOptions';
import AllItems from './AllItems';
import StockDetails from './StockDetails';
import StockNeeded from './StockNeeded';
import PriceList from './PriceList';
import GlobalParLevel from './GlobalParLevel';

const reducers = combineReducers({
    theme: Theme,
    auth: Auth,
    purchaseOrders: PurchaseOrders,
    purchaseOrderFilters: PurchaseOrderFilters,
    suppliers: Suppliers,
    buyers: Buyers,
    searchSupplier: SearchSupplier,
    bocFilterToggleOptions: ToggleBOCFilterOptions,
    standingPurchaseOrders: StandingPurchaseOrders,
    searchPreferredItem: SearchPreferredItem,
    salesOnlyItems: SalesOnly,
    allItems: AllItems,
    stockNeeded: StockNeeded,
    stockDetails: StockDetails,
    priceList: PriceList,
    globalParLevel: GlobalParLevel,
});

export default reducers;