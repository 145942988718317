import moment from 'moment';
import {
  ON_BUYER_CHANGE,
  ON_CHANGE_FROM_DATE,
  ON_CHANGE_TO_DATE,
  ON_PRIORITY_CHANGE,
  ON_STATUS_CHANGE,
  ON_SUPPLIER_CHANGE, RESET_FILTERS,
} from '../constants/PurchaseOrderFilters';
import {DATE_FORMAT_YYYY_MM_DD} from '../../constants/DateConstant';

const initFilters = {
  fromDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
  toDate: moment().add(1, 'days').format(DATE_FORMAT_YYYY_MM_DD),
  supplier: null,
  buyer: null,
  priority: 'all',
  status: 'all',
};

const PurchaseOrderFilters = (state = initFilters, action) => {
  switch (action.type) {
    case ON_CHANGE_FROM_DATE:
      return {
        ...state,
        fromDate: action.fromDate,
      };
    case ON_CHANGE_TO_DATE:
      return {
        ...state,
        toDate: action.toDate,
      };
    case ON_SUPPLIER_CHANGE:
      return {
        ...state,
        supplier: action.supplier,
      };
    case ON_BUYER_CHANGE:
      return {
        ...state,
        buyer: action.buyer,
      };
    case ON_PRIORITY_CHANGE:
      return {
        ...state,
        priority: action.priority,
      };
    case ON_STATUS_CHANGE:
      return {
        ...state,
        status: action.status,
      };
    case RESET_FILTERS:
      return initFilters;
    default:
      return state;
  }
};

export default PurchaseOrderFilters;