import {SET_ITEM_PRICE_LIST_META, SET_ITEM_PRICE_LIST_FILTERS, RESET_ITEM_PRICE_LIST_FILTERS, SET_ITEM_PRICE_LIST } from '../constants/PriceList';
import moment from 'moment';
import {DATE_FORMAT_YYYY_MM_DD} from '../../constants/DateConstant';

const defaultFilters = () => ({
  fromDate: moment().subtract(3, 'months').format(DATE_FORMAT_YYYY_MM_DD),
  toDate: moment().format(DATE_FORMAT_YYYY_MM_DD),
  itemId: null
});

const initState = {
  prices: null,
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
}

const PriceList = (state = initState, action) => {
  switch (action.type) {
    case SET_ITEM_PRICE_LIST_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_ITEM_PRICE_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      };
    case RESET_ITEM_PRICE_LIST_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_ITEM_PRICE_LIST:
      return {
        ...state,
        prices: action.payload,
      };
    default:
      return state;
  }
}

export default PriceList;