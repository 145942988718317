import { message } from "antd"
import { debounce as lodashDebounce } from 'lodash'
import originalDayJs from "dayjs"

import relativeTime from "dayjs/plugin/relativeTime"
import utc from "dayjs/plugin/utc"
import timezone from "dayjs/plugin/timezone"
import advancedFormat from "dayjs/plugin/advancedFormat"
import customParseFormat from "dayjs/plugin/customParseFormat"
import isToday from "dayjs/plugin/isToday"
import isTomorrow from "dayjs/plugin/isTomorrow"
import isYesterday from "dayjs/plugin/isYesterday"
import duration from "dayjs/plugin/duration"
import {detect} from "detect-browser"

const notificationDurationSeconds = 6

const LONG_DEBOUNCE_WAIT_MILLISECONDS = 450
export const DEFAULT_DEBOUNCE_WAIT_MILLISECONDS = 400
const SHORT_DEBOUNCE_WAIT_MILLISECONDS = 300

originalDayJs.extend(relativeTime)
originalDayJs.extend(utc)
originalDayJs.extend(timezone)
originalDayJs.extend(duration)
originalDayJs.extend(advancedFormat)
originalDayJs.extend(customParseFormat)
originalDayJs.extend(isToday)
originalDayJs.extend(isTomorrow)
originalDayJs.extend(isYesterday)

export const dayjs = originalDayJs

const defaultTimezone = "Australia/Sydney"

export function debounce(target_function) {
    return lodashDebounce(target_function, DEFAULT_DEBOUNCE_WAIT_MILLISECONDS)
}

export function longDebounce(target_function) {
    return lodashDebounce(target_function, LONG_DEBOUNCE_WAIT_MILLISECONDS)
}

export function shortDebounce(target_function) {
    return lodashDebounce(target_function, SHORT_DEBOUNCE_WAIT_MILLISECONDS)
}

export function notifySuccess(messageContent) {
    message.success(messageContent, notificationDurationSeconds).then()
}

export function notifyWarning(messageContent) {
    message.warning(messageContent, notificationDurationSeconds).then()
}

export function notifyError(messageContent) {
    message.error(messageContent, notificationDurationSeconds).then()
}

export function notifyAxiosError(axiosError, handlers = {}) {
    console.error(axiosError)

    if (axiosError.response) {
        if (handlers.hasOwnProperty("onResponseError")) {
            handlers.onResponseError(axiosError.response)
        }

        if (axiosError.response.status === 419) {
            notifyError("Your session has been expired. Please refresh the page or log in again.")

            if (handlers.hasOwnProperty("onSessionExpiredError")) {
                handlers.onSessionExpiredError(axiosError.response)
            }

            return
        }

        if (axiosError.response.status === 422) {
            notifyError(axiosError.response?.data?.message ?? "Server failed to process data.")

            if (handlers.hasOwnProperty("onUnprocessableEntityError")) {
                handlers.onUnprocessableEntityError(axiosError.response)
            }

            return
        }

        if (axiosError.response.status === 409) {
            notifyError(axiosError.response?.data?.message ?? "Server failed to process data.")

            if (handlers.hasOwnProperty("onConflictError")) {
                handlers.onUnprocessableEntityError(axiosError.response)
            }

            return
        }

        if (axiosError.response.status === 401) {
            if (handlers.hasOwnProperty('onUnauthenticatedError')) {
                handlers.onUnauthenticatedError(axiosError)
                return
            }
        }

        notifyError(axiosError.response.data?.message ?? "Server error")
    }
    else if (axiosError.request) {
        if (handlers.hasOwnProperty("onRequestFailure")) {
            handlers.onRequestFailure(axiosError.response)
        }

        notifyError(<> Failed to send request, please check your Internet connection / browser settings. </>)
    } else {
        if (handlers.hasOwnProperty("onUnknownFailure")) {
            handlers.onUnknownFailure(axiosError.response)
        }

        notifyError("Unknown error.")
    }
}

export function removeTrailingZeroesFromNumber(number) {
    if (number === null || number === undefined) {
        return number
    }

    return parseFloat(parseFloat(number))
}

export function formatQuantity(value) {
    return (Intl.NumberFormat("en-AU")).format(value)
}

export function formatPercentage(value) {
    return (Intl.NumberFormat("en-AU")).format(value) + "%"
}

export function formatCurrency(value) {
    return (Intl.NumberFormat("en-AU", {
        style: "currency",
        currency: "AUD",
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
    })).format(value)
}

export function formatDatetimeFancy(value, timezone = null) {
    return originalDayJs(value)
        .tz(timezone ?? defaultTimezone)
        .format("dddd, MMMM D, YYYY h:mm A z")
}

export function formatDatetimeFancyWithoutTimezone(value, timezone = null) {
    return originalDayJs(value)
        .tz(timezone ?? defaultTimezone)
        .format("dddd, MMMM D, YYYY h:mm A")
}

export function formatDateFancyWithoutTimezone(value) {
    return originalDayJs(value)
        .format("dddd, MMMM D, YYYY")
}

export function formatDateFancyWithLocalTz(value) {
    return originalDayJs(value).format("dddd, MMMM D, YYYY")
}

export function formatDateFancy(value, timezone = null) {
    return originalDayJs(value)
        .tz(timezone ?? defaultTimezone)
        .format("dddd, MMMM D, YYYY")
}

export function formatDatetimeRelative(value, timezone = null) {
    return originalDayJs()
        .tz(timezone ?? defaultTimezone)
        .to(originalDayJs(value))
}

export function formatDateRelativeInDays(value, timezone = null) {
    const time = originalDayJs(value).tz(timezone ?? defaultTimezone)

    if (time.isToday()) {
        return "Today"
    }

    if (time.isTomorrow()) {
        return "Tomorrow"
    }

    if (time.isYesterday()) {
        return "Yesterday"
    }

    const dayDiff = time.diff(originalDayJs().startOf("day"), 'day')
    return dayDiff < 0 ?
        `${Math.abs(dayDiff)} days ago` :
        `${Math.abs(dayDiff)} days from today`
}

export function formatDateRelative(value, timezone = null) {
    const time = originalDayJs(value).tz(timezone ?? defaultTimezone)
    return time.fromNow()
}

export function detectIsPortrait() {
    if (['safari', 'ios'].includes(detect().name)) {
        return window.matchMedia("(orientation: portrait)").matches
    }

    const orientationType = window?.screen?.orientation?.type

    if (!orientationType) {
        console.error('Can not determine the browser orientation.')
    }

    return !(["landscape-primary", "landscape-secondary"].includes(orientationType))
}