import React, {useEffect, useState} from 'react';
import {useSelector} from 'react-redux';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import Views from './views';
import {ThemeSwitcherProvider} from 'react-css-theme-switcher';
import {THEME_CONFIG} from './configs/AppConfig';
import authService from './services/AuthService';
import Loading from './components/shared-components/Loading';

const themes = {
  dark: `${process.env.PUBLIC_URL}/css/dark-theme.css`,
  light: `${process.env.PUBLIC_URL}/css/light-theme.css`,
};

const MainContainer = () => {
  return (
      <Router>
        <Switch>
          <Route path="/" component={Views}/>
        </Switch>
      </Router>
  );
};

function App() {
  const profile = useSelector(state => state.auth.profile);
  const token = useSelector(state => state.auth.token);
  const [isInitialized, setIsInitialized] = useState(!!profile);

  useEffect(() => {
    if (isInitialized) {
      return;
    }
    if (!token) {
      setIsInitialized(true);
      return;
    }
    authService.verifyProfile(token).then(() => {
      setIsInitialized(true);
    });
  }, [token, isInitialized]);

  return (
      <div className="App">
        <ThemeSwitcherProvider themeMap={themes}
                               defaultTheme={THEME_CONFIG.currentTheme}
                               insertionPoint="styles-insertion-point">
          {
            isInitialized ? <MainContainer/> : <Loading cover="content"/>
          }
        </ThemeSwitcherProvider>
      </div>
  );
}

export default App;
