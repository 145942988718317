import {Button} from "antd"
import React from "react"

export function CompactButton(props) {
    const {style, ...remainingProps} = props

    return (
        <Button
            style={{
                wordBreak: 'break-all',
                whiteSpace: 'break-spaces',
                fontWeight: 'bold',
                height: 'auto',
                textAlign: 'right',
                padding: '0.1rem 0.2rem',
                ...style
            }}
            {...remainingProps}
        >
            {props.children}
        </Button>
    )
}