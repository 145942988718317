import {SET_SALES_ONLY_META, SET_SALES_ONLY_FILTERS, RESET_SALES_ONLY_FILTERS, SET_SALES_ONLY_ITEMS, APPEND_SALES_ONLY_ITEMS} from '../constants/SalesOnly';

const defaultFilters = () => ({
  toggleMode: "category",
  toggleOption: null,
  toggleOptionLabel: "",
  itemKeyword: "",
  page: 1,
  pageCount: 0,
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  }
}

const SalesOnly = (state=initState, action) => {
  switch (action.type) {
    case SET_SALES_ONLY_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_SALES_ONLY_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      };
    case RESET_SALES_ONLY_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_SALES_ONLY_ITEMS:
      return {
        ...state,
        list: [...action.payload],
      };
    case APPEND_SALES_ONLY_ITEMS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };
    default:
      return state;
  }
}

export default SalesOnly;