import {
  APPEND_ITEMS,
  RESET_ALL_ITEMS_FILTERS,
  SET_ALL_ITEMS,
  SET_ALL_ITEMS_FILTERS,
  SET_ALL_ITEMS_META,
} from '../constants/AllItems';

const defaultFilters = () => ({
  toggleMode: 'category',
  toggleOption: null,
  itemKeyword: '',
  page: 1,
  pageCount: 0,
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
};

const AllItems = (state = initState, action) => {
  switch (action.type) {
    case SET_ALL_ITEMS_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_ALL_ITEMS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case RESET_ALL_ITEMS_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case APPEND_ITEMS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };
    case SET_ALL_ITEMS:
      return {
        ...state,
        list: [...action.payload],
      };
    default:
      return state;
  }
};

export default AllItems;