import {SET_BUYER_DROP_DOWN_META, SET_BUYER_DROP_DOWN_LIST} from '../constants/Buyers';

const initState = {
  list: [],
  meta: {
    initialized: false,
    loading: false,
  },
};

const Buyers = (state=initState, action) => {
  switch (action.type) {
    case SET_BUYER_DROP_DOWN_META:
      return {
        list: [...state.list],
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_BUYER_DROP_DOWN_LIST:
      return {
        list: [...action.payload],
        meta: {...state.meta},
      };
    default:
      return state;
  }
}

export default Buyers;