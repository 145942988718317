import React from 'react'
import {Switch, Route, Redirect} from 'react-router-dom';
import AuthViews from 'views/auth-views'
import {useSelector} from 'react-redux';
import {APP_PREFIX_PATH} from '../../configs/AppConfig';

export const AuthLayout = () => {
	const profile = useSelector(state => state.auth.profile);
	return profile ? (<Redirect to={APP_PREFIX_PATH} />) : (
		<div className="auth-container">
			<Switch>
				<Route path="" component={AuthViews} />
			</Switch>
		</div>
	)
}


export default AuthLayout
