import React from 'react'
import ReactDOM from 'react-dom'
import {Provider} from 'react-redux'
import App from './App'
import * as serviceWorker from './serviceWorker'
import './index.css'
import './assets/css/buyers.css'
import store from './redux/store'
import * as Sentry from "@sentry/react"
import {BrowserTracing} from "@sentry/tracing"
import {SENTRY_DSN} from "./env"

Sentry.init({
    dsn: SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 0.1,
});

ReactDOM.render(
    <React.StrictMode>
        <Provider store={store}>
            <App/>
        </Provider>
    </React.StrictMode>,
    document.getElementById('root')
)

serviceWorker.register()
