import {all, call, fork, put, takeEvery} from 'redux-saga/effects';
import {AUTH_TOKEN, SIGNIN, SIGNOUT} from '../constants/Auth';
import {
  authenticated,
  hideLoading,
  setErrors,
  showAuthMessage,
  signOutSuccess,
} from '../actions/Auth';

import AuthService from '../../services/AuthService';
import {message as AntMessage} from 'antd';

export function* signIn() {
  yield takeEvery(SIGNIN, function* ({payload}) {
    const {email, password} = payload;
    try {
      const {user: profile, token} = yield call(AuthService.signIn, email,
          password);
      localStorage.setItem(AUTH_TOKEN, token);
      yield put(authenticated(token, profile));
    } catch (err) {
      const {data: {message, errors}} = err?.response;
      const errorObj = {message};
      if (errors) {
        errorObj.errors = Object.fromEntries(
            Object.keys(errors).map(name => [name, errors[name].pop()]));
      } else {
        errorObj.errors = {};
      }
      yield put(
          showAuthMessage(errorObj?.message || err?.response?.statusText));
      yield put(setErrors(errorObj.errors));
    } finally {
      yield put(hideLoading());
    }
  });
}

export function* signOut() {
  yield takeEvery(SIGNOUT, function* () {
    try {
      const {message: signOutMessage} = yield call(AuthService.signOut);
      AntMessage.success(signOutMessage);
      localStorage.removeItem(AUTH_TOKEN);
      yield put(signOutSuccess());
    } catch (err) {
      AntMessage.error(err?.response?.statusText);
    }
  });
}

export default function* rootSaga() {
  yield all([
    fork(signIn),
    fork(signOut),
  ]);
}
