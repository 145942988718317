import {
  SET_SUPPLIER_DROP_DOWN_META,
  SET_SUPPLIER_DROP_DOWN_LIST
} from '../constants/Suppliers';

const initState = {
  list: [],
  meta: {
    initialized: false,
    loading: false,
  },
};

const Suppliers = (state=initState, action) => {
  switch (action.type) {
    case SET_SUPPLIER_DROP_DOWN_META:
      return {
        list: [...state.list],
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_SUPPLIER_DROP_DOWN_LIST:
      return {
        list: [...action.payload],
        meta: {...state.meta},
      };
    default:
      return state;
  }
}

export default Suppliers;