import React, {useEffect, useState} from 'react'
import {connect} from 'react-redux'
import {Layout} from 'antd'
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons'
import {onMobileNavToggle, toggleCollapsedNav} from 'redux/actions/Theme'
import {NAV_TYPE_TOP, SIDE_NAV_COLLAPSED_WIDTH, SIDE_NAV_WIDTH} from 'constants/ThemeConstant'
import utils from 'utils'
import MyProfileMenu from './MyProfileMenu'
import {CompactButton} from "../../probros/CompactButton"

const {Header} = Layout

export const HeaderNav = props => {
    const {
        navCollapsed, mobileNav, navType, headerNavColor, toggleCollapsedNav, onMobileNavToggle, isMobile, currentTheme,
    } = props
    const [searchActive, setSearchActive] = useState(false)

    const onSearchClose = () => {
        setSearchActive(false)
    }

    const onToggle = () => {
        if (!isMobile) {
            toggleCollapsedNav(!navCollapsed)
        } else {
            onMobileNavToggle(!mobileNav)
        }
    }

    const isNavTop = navType === NAV_TYPE_TOP

    const mode = () => {
        if (!headerNavColor) {
            return utils.getColorContrast(currentTheme === 'dark' ? '#00000' : '#ffffff')
        }
        return utils.getColorContrast(headerNavColor)
    }
    const navMode = mode()
    const getNavWidth = () => {
        if (isNavTop || isMobile) {
            return '0px'
        }
        if (navCollapsed) {
            return `${SIDE_NAV_COLLAPSED_WIDTH}px`
        } else {
            return `${SIDE_NAV_WIDTH}px`
        }
    }

    useEffect(() => {
        if (!isMobile) {
            onSearchClose()
        }
    })

    return (<Header
            className={`app-header ${navMode}`}
            style={{
                backgroundColor: headerNavColor, height: '40px'
            }}
        >
            <div className={`app-header-wrapper ${isNavTop ? 'layout-top-nav' : ''}`}>
                <div style={{
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    paddingLeft: '20px',
                    paddingRight: '20px'
                }}>
                    <CompactButton
                        onClick={onToggle}
                        size='small'
                        icon={navCollapsed || isMobile ? <MenuUnfoldOutlined className="nav-icon"/> :
                            <MenuFoldOutlined className="nav-icon"/>}
                    >

                    </CompactButton>

                    <MyProfileMenu/>

                </div>
            </div>
        </Header>)
}

const mapStateToProps = ({theme}) => {
    const {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction} = theme
    return {navCollapsed, navType, headerNavColor, mobileNav, currentTheme, direction}
}

export default connect(mapStateToProps, {toggleCollapsedNav, onMobileNavToggle})(HeaderNav)