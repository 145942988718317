import React from 'react'
import {connect} from "react-redux"
import {Avatar, Button, Dropdown, Menu} from "antd"
import {LogoutOutlined} from '@ant-design/icons'
import {signOut} from '../../redux/actions/Auth'
import {CompactButton} from "../../probros/CompactButton"

const ProfileName = ({profile}) => {
    const firstName = profile?.first_name || ""
    return firstName.charAt(0).toUpperCase()
}

const MyProfileMenu = ({profile, signOut, topNavColor}) => {
    return (
        <CompactButton
            onClick={signOut}
            danger={true}
            size="small"
            icon={<LogoutOutlined/>}
        >
            Sign Out
        </CompactButton>
    )
}

const mapStateToProps = ({auth, theme}) => {
    const {profile} = auth
    const {topNavColor} = theme
    return {profile, topNavColor}
}

const mapDispatchToProps = {
    signOut,
}
export default connect(mapStateToProps, mapDispatchToProps)(MyProfileMenu)