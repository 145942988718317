import fetch, {setAuthorizationToken} from 'auth/FetchInterceptor';
import {setProfile} from '../redux/actions/Auth';
import store from '../redux/store';

const AuthService = {};

AuthService.signIn = (email, password) => {
  return fetch({
    url: 'auth/login',
    method: 'post',
    headers: {
      'public-request': 'true',
    },
    data: {
      email: email,
      password: password,
    },
  });
};

AuthService.signOut = () => {
  return fetch({
    url: 'auth/logout',
    method: 'post',
  });
};

AuthService.verifyProfile = (token) => {
  setAuthorizationToken(token);
  return fetch({
    url: 'auth/profile',
    method: 'get',
  }).then(res => {
    const {user} = res;
    store.dispatch(setProfile(user));
  }).catch(err => console.dir(err));
};

export default AuthService;