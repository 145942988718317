import {SET_GLOBAL_PAR_LEVEL_DATES_META, SET_GLOBAL_PAR_LEVEL_DATES} from '../constants/GlobalParLevel';

const initState = {
  dates: [],
  meta: {
    initialized: false,
    loading: false,
  },
}

const GlobalParLevel = (state = initState, action) => {
  switch (action.type) {
    case SET_GLOBAL_PAR_LEVEL_DATES_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_GLOBAL_PAR_LEVEL_DATES:
      return {
        ...state,
        dates: [...action.payload],
      };
    default:
      return state;
  }
}

export default GlobalParLevel;