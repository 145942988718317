import {SET_SEARCH_PREFERRED_ITEM_META,SET_SEARCH_PREFERRED_ITEM_LIST} from '../constants/SearchPreferredItem';

const initState = {
  list: [],
  meta: {
    loading: false,
  },
};

const SearchPreferredItem = (state=initState, action) => {
  switch (action.type) {
    case SET_SEARCH_PREFERRED_ITEM_META:
      return {
        list: [...state.list],
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_SEARCH_PREFERRED_ITEM_LIST:
      return {
        list: [...action.payload],
        meta: {...state.meta},
      };
    default:
      return state;
  }
}

export default SearchPreferredItem;