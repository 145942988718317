import React, {Component} from 'react'
import PropTypes from "prop-types"
import {Button, Card, Col, Input, Radio, Row, Space, Spin, Table, Tag} from "antd"
import axios from "auth/NewFetchInterceptor"
import {formatQuantity, notifyAxiosError, notifySuccess} from "./utilities"
import {SyncOutlined} from "@ant-design/icons"
import {debounce, isEqual, omit} from "lodash";

class ProcurementCentralKitItemStockIndex extends Component {
    constructor(props) {
        super(props)

        this.state = {
            search: '',
            page_count: 1,
            per_page: 100,
            page: 1,
            total: 0,

            loading: false,
            only_show_assigned: true,
            only_show_active: false,
            kit_items: [],
        }
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({loading: true}, () => {
            axios.get(
                `/procurement-central/kit-item-stock`,
                {params: this.fetchParams()}
            ).then(response => {
                this.setState({
                    kit_items: response.data.data.map(kit_item => {
                        return this.assignStateToKitItem(kit_item);
                    }),

                    per_page: response.data.meta.per_page,
                    page: response.data.meta.current_page,
                    total: response.data.meta.total,
                    page_count: response.data.meta.last_page,
                })
            }).catch(
                notifyAxiosError
            ).finally(() => {
                this.setState({
                    loading: false
                })
            })
        })
    }

    debouncedFetchData = debounce(this.fetchData, 800)

    assignStateToKitItem = kit_item => ({
        ...kit_item,
        new_stock: '',
        new_stock_comment: '',
        updating: false,
        deleting: false,
        original_attributes: {
            ...kit_item,
            new_stock: '',
            new_stock_comment: '',
            updating: false,
            deleting: false,
        }
    });

    kitItemIsModified = kit_item => {
        return !isEqual(
            omit(kit_item, ['original_attributes']),
            kit_item.original_attributes
        )
    }

    modifyKitItem = (kitItemId, updates, callback = () => {
    }) => {
        this.setState({
            kit_items: this.state.kit_items.map(ki => {
                return ki.id === kitItemId ? {...ki, ...updates} : ki;
            }, callback)
        })
    };

    fetchParams = () => ({
        search: this.state.search,
        page: this.state.page,
        per_page: this.state.per_page,
        only_show_assigned: this.state.only_show_assigned,
        only_show_active: this.state.only_show_active,
    })

    render() {
        let value = this.state.kit_items;
        return (
            <Spin spinning={this.state.loading}>
                <Card size='small'>
                    <Space direction="vertical" style={{ width: '100%' }}>
                        <Row>
                            <Col lg={22} xs={24}>
                                <Input.Search
                                    onSearch={() => {
                                        this.fetchData()
                                    }}
                                    value={this.state.search}
                                    onChange={e => {
                                        this.setState({
                                            search: e.target.value
                                        }, this.debouncedFetchData)
                                    }}
                                    placeholder="Search..."
                                />

                            </Col>

                            <Col lg={2} xs={24}>
                                <Button
                                    onClick={this.fetchData}
                                    size="small"
                                    loading={this.state.loading}
                                    icon={<SyncOutlined/>}>
                                    Reload Data
                                </Button>
                            </Col>
                        </Row>

                        <Space style={{ width: '100%' }}>
                            <Radio.Group
                                size="small"
                                buttonStyle='solid'
                                value={this.state.only_show_assigned}
                                onChange={e => {
                                    this.setState({
                                        only_show_assigned: e.target.value
                                    }, this.fetchData)
                                }}>
                                <Radio.Button key='true' value={true}> Only Assigned </Radio.Button>
                                <Radio.Button key='false' value={false}> All </Radio.Button>
                            </Radio.Group>

                            <Radio.Group
                                size="small"
                                buttonStyle='solid'
                                value={this.state.only_show_active}
                                onChange={e => {
                                    this.setState({
                                        only_show_active: e.target.value
                                    }, this.fetchData)
                                }}>
                                <Radio.Button key='true' value={true}> Only Active </Radio.Button>
                                <Radio.Button key='false' value={false}> All </Radio.Button>
                            </Radio.Group>
                        </Space>
                    </Space>

                    <Table
                        size="small"
                        dataSource={this.state.kit_items}

                        pagination={{
                            current: this.state.page,
                            pageSize: this.state.per_page,
                            total: this.state.total,
                        }}

                        onChange={(pagination) => {
                            this.setState({
                                per_page: pagination.pageSize,
                                page: pagination.current,
                            }, this.debouncedFetchData)
                        }}

                        rowKey={kit_item => kit_item.id}

                        onRow={kit_item => {
                            return ({
                                style: {
                                    backgroundColor: this.kitItemIsModified(kit_item) ?
                                        'yellow' :
                                        'initial'
                                }
                            })
                        }}

                        columns={[
                            {
                                key: 'kit_item_name',
                                title: 'Item',
                                width: 200,
                                render: kit_item => (
                                    <Space>
                                        <span> {kit_item.item_name} </span>

                                        {kit_item.status === 'active' ?
                                            <Tag style={{ color: 'white', backgroundColor: 'darkgreen' }}> Active </Tag> :
                                            <Tag style={{ color: 'white', backgroundColor: 'darkred' }}> Inactive </Tag>
                                        }
                                    </Space>
                                ),
                            },

                            {
                                key: 'stock',
                                title: 'Current Stock',
                                width: 200,
                                onHeaderCell: () => ({style: {textAlign: 'right'}}),
                                onCell: () => ({style: {textAlign: 'right'}}),
                                render: kit_item => formatQuantity(kit_item.stock)
                            },

                            {
                                key: 'new_stock',
                                title: 'New Stock',
                                width: 200,
                                onHeaderCell: () => ({style: {textAlign: 'right'}}),
                                onCell: () => ({style: {textAlign: 'right'}}),
                                render: kit_item => (
                                    <input
                                        style={{textAlign: 'right'}}
                                        value={kit_item.new_stock}
                                        onChange={e => {
                                            this.modifyKitItem(kit_item.id, {new_stock: e.target.value})
                                        }}
                                        placeholder="New Stock"
                                        onKeyDown={e => {
                                            if (e.key === 'Enter') {
                                                this.massUpdateKitItems();
                                            }
                                        }}
                                    />
                                )
                            },
                        ]}
                    />
                </Card>
            </Spin>
        )
    }

    massUpdateKitItems = () => {
        axios
            .post(`/procurement-central/kit-item-stock/mass-update`, {
                kit_items: this.state.kit_items
                    .filter(ki => this.kitItemIsModified(ki))
                    .map(ki => ({
                        id: ki.id,
                        new_stock: ki.new_stock,
                        new_stock_comment: ki.new_stock_comment
                    }))
            })
            .then(response => {
                const updatedKitItems = response.data.data

                this.setState({
                    kit_items: this.state.kit_items.map(ki => {
                        const found = updatedKitItems.find(uki => uki.id === ki.id)
                        return found ? this.assignStateToKitItem(found) : ki;
                    })
                }, () => {
                    notifySuccess('Successfully updated stocks')
                })
            })
            .catch(notifyAxiosError)
    };
}

ProcurementCentralKitItemStockIndex.propTypes = {
    parentItemId: PropTypes.string,
    expectedShipmentDateStart: PropTypes.string,
    expectedShipmentDateEnd: PropTypes.string,
}

export default ProcurementCentralKitItemStockIndex