import {SET_STOCK_DETAILS_META, SET_STOCK_DETAILS_FILTERS, RESET_STOCK_DETAILS_FILTERS, SET_STOCK_DETAILS, APPEND_SET_STOCK_DETAILS} from '../constants/StockDetails';

const defaultFilters = () => ({
  itemKeyword: "",
  page: 1,
  pageCount: 0,
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
}

const StockDetails = (state = initState, action) => {
  switch (action.type) {
    case SET_STOCK_DETAILS_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_STOCK_DETAILS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      };
    case RESET_STOCK_DETAILS_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_STOCK_DETAILS:
      return {
        ...state,
        list: [...action.payload],
      };
    case APPEND_SET_STOCK_DETAILS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };
    default:
      return state;
  }
}

export default StockDetails;