import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'
import ProcurementCentralKitItemStockIndex from "../../probros/ProcurementCentralKitItemStockIndex";

export const AppViews = () => {
  return (
    <Suspense fallback={<Loading cover="content"/>}>
      <Switch>
        <Route path={`${APP_PREFIX_PATH}/home`} component={lazy(() => import(`./home`))} />
        <Route path={`${APP_PREFIX_PATH}/buying`} component={lazy(() => import(`./buying`))} />
        <Route path={`${APP_PREFIX_PATH}/buyer-order-central`} component={lazy(() => import(`./buyer-order-central`))} />
        <Route path={`${APP_PREFIX_PATH}/purchase-orders`} component={lazy(() => import(`./purchase-orders`))} />

        <Route
            path={`${APP_PREFIX_PATH}/standing-purchase-orders`}
            component={lazy(() => import(`./standing-purchase-orders`))}
        />

        <Route
            path={`${APP_PREFIX_PATH}/sales-report-by-kit-item`}
            component={lazy(() => import('../../probros/SalesReportByKitItemIndex'))}
        />

        <Route
            path={`${APP_PREFIX_PATH}/kit-item-stock`}
            component={lazy(() => import('../../probros/ProcurementCentralKitItemStockIndex'))}
        />



        <Route
            path={`${APP_PREFIX_PATH}/supplier`}
            component={lazy(() => import('../../probros/SupplierIndex'))}
        />


        <Route
            path={`${APP_PREFIX_PATH}/missing-sales-order-items-summary-index`}
            component={lazy(() => import('../../probros/ProcurementCentralMissingSalesOrderItemSummary'))}
        />

        <Route
            path={`${APP_PREFIX_PATH}/incomplete-purchase-order-item-index`}
            component={lazy(() => import('../../probros/IncompletePurchaseOrderItemIndex'))}
        />

        <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/home`} />
      </Switch>
    </Suspense>
  )
}

export default React.memo(AppViews);