import {
    BarcodeOutlined,
    DashboardOutlined,
    DollarOutlined, OrderedListOutlined,
    PlusCircleOutlined,
    RiseOutlined,
    ShopOutlined,
    ShoppingCartOutlined,
    ShoppingOutlined,
    StockOutlined, TagOutlined
} from '@ant-design/icons'
import {APP_PREFIX_PATH} from 'configs/AppConfig'

const navigationConfig = [
    {
        key: 'dashboard',
        path: `${APP_PREFIX_PATH}/home`,
        title: 'sidenav.dashboard',
        icon: DashboardOutlined,
        breadcrumb: false,
        submenu: []
    },

    {
        key: 'purchase-orders',
        path: "",
        title: 'sidenav.purchaseOrders',
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
            {
                key: 'purchase-orders-list',
                path: `${APP_PREFIX_PATH}/purchase-orders`,
                title: 'sidenav.purchaseOrders',
                icon: ShoppingCartOutlined,
                breadcrumb: true,
                submenu: []
            },

            {
                key: 'incomplete-purchase-order-item-index',
                path: `${APP_PREFIX_PATH}/incomplete-purchase-order-item-index`,
                title: 'sidenav.incompletePurchaseOrderItemIndex',
                icon: ShoppingCartOutlined,
                breadcrumb: true,
                submenu: []
            },

            {
                key: 'missing-sales-order-items-summary-index',
                path: `${APP_PREFIX_PATH}/missing-sales-order-items-summary-index`,
                title: 'sidenav.missingSalesOrderItemsSummaryIndex',
                icon: ShoppingCartOutlined,
                breadcrumb: true,
                submenu: []
            },

            {
                key: 'sales-report-by-kit-item',
                path: `${APP_PREFIX_PATH}/sales-report-by-kit-item`,
                title: 'sidenav.salesReportByKitItemIndex',
                icon: ShoppingCartOutlined,
                breadcrumb: true,
                submenu: []
            },

            {
                key: 'kit-item-stock',
                path: `${APP_PREFIX_PATH}/kit-item-stock`,
                title: 'sidenav.kitItemStockIndex',
                icon: OrderedListOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },

    {
        key: 'supplier',
        path: `${APP_PREFIX_PATH}/supplier`,
        title: 'sidenav.buying',
        icon: TagOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'supplier.index',
                path: `${APP_PREFIX_PATH}/supplier`,
                title: 'sidenav.supplier.index',
                icon: TagOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },

    {
        key: 'buying',
        path: `${APP_PREFIX_PATH}/buying`,
        title: 'sidenav.buying',
        icon: ShoppingOutlined,
        breadcrumb: false,
        submenu: [
            {
                key: 'buying-price-list',
                path: `${APP_PREFIX_PATH}/buying/price-list`,
                title: 'sidenav.buying.priceList',
                icon: DollarOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: 'stock-details',
                path: `${APP_PREFIX_PATH}/buying/stock-details`,
                title: 'sidenav.buying.stockDetails',
                icon: StockOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },

    {
        key: 'buyer-order-central',
        path: `${APP_PREFIX_PATH}/buyer-order-central`,
        title: 'sidenav.buyerOrderCentral',
        icon: ShoppingOutlined,
        breadcrumb: true,
        submenu: [
            {
                key: 'buyer-order-central-sales-only',
                path: `${APP_PREFIX_PATH}/buyer-order-central/sales-only`,
                title: 'sidenav.buyerOrderCentral.salesOnly',
                icon: ShopOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: 'stock-needed',
                path: `${APP_PREFIX_PATH}/buyer-order-central/stock-needed`,
                title: 'sidenav.buyerOrderCentral.stockNeeded',
                icon: RiseOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: 'all-items',
                path: `${APP_PREFIX_PATH}/buyer-order-central/all-items`,
                title: 'sidenav.buyerOrderCentral.allItems',
                icon: BarcodeOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    },

    {
        key: 'standing-purchase-orders',
        path: "",
        title: 'sidenav.standingPurchaseOrders',
        icon: ShoppingCartOutlined,
        breadcrumb: true,
        submenu: [
            {
                key: 'standing-purchase-orders-list',
                path: `${APP_PREFIX_PATH}/standing-purchase-orders`,
                title: 'sidenav.standingPurchaseOrders',
                icon: ShoppingCartOutlined,
                breadcrumb: true,
                submenu: []
            },
            {
                key: 'new-standing-purchase-order',
                path: `${APP_PREFIX_PATH}/standing-purchase-orders/new`,
                title: 'sidenav.standingPurchaseOrders.new',
                icon: PlusCircleOutlined,
                breadcrumb: true,
                submenu: []
            }
        ]
    }
]

export default navigationConfig
