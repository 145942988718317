import React, {useEffect} from 'react';
import {
  Redirect,
  Route,
  Switch,
  useHistory,
  withRouter,
} from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import AppLayout from 'layouts/app-layout';
import AuthLayout from 'layouts/auth-layout';
import AppLocale from 'lang';
import {IntlProvider} from 'react-intl';
import {ConfigProvider} from 'antd';
import {APP_PREFIX_PATH, AUTH_PREFIX_PATH} from 'configs/AppConfig';
import useBodyClass from 'hooks/useBodyClass';
import {setProfile, signOutSuccess} from '../redux/actions/Auth';

const Divert = ({path}) => {
  const history = useHistory();

  useEffect(() => {
    history.push(path);
  }, [history, path]);

  return null;
};

const ProperDivert = () => {
  const profile = useSelector(state => state.auth.profile);
  return <Divert path={profile ?
      APP_PREFIX_PATH :
      AUTH_PREFIX_PATH}/>;
};

export const Views = (props) => {
  const {
    locale,
    location,
    direction,
  } = props;
  const currentAppLocale = AppLocale[locale];
  useBodyClass(`dir-${direction}`);

  return (
      <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>
        <ConfigProvider locale={currentAppLocale.antd} direction={direction}>
          <Switch>
            <Route exact path="/">
              <Redirect to={APP_PREFIX_PATH}/>
            </Route>
            <Route path={AUTH_PREFIX_PATH}>
              <AuthLayout direction={direction}/>
            </Route>
            <Route path={APP_PREFIX_PATH}>
              <AppLayout direction={direction} location={location}/>
            </Route>
            <Route path="*" component={ProperDivert}/>
          </Switch>
        </ConfigProvider>
      </IntlProvider>
  );
};

const mapStateToProps = ({theme, auth}) => {
  const {locale, direction} = theme;
  const {token, profile} = auth;
  return {locale, token, direction, profile};
};

const mapDispatchToProps = {
  setProfile, signOutSuccess,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Views));