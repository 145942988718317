import {
  APPEND_STOCK_NEEDED_ITEMS,
  RESET_STOCK_NEEDED_FILTERS,
  SET_STOCK_NEEDED_FILTERS,
  SET_STOCK_NEEDED_ITEMS,
  SET_STOCK_NEEDED_META,
} from '../constants/StockNeeded';

const defaultFilters = () => ({
  toggleMode: 'category',
  toggleOption: null,
  itemKeyword: '',
  page: 1,
  pageCount: 0,
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
};

const StockNeeded = (state = initState, action) => {
  switch (action.type) {
    case SET_STOCK_NEEDED_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_STOCK_NEEDED_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case RESET_STOCK_NEEDED_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_STOCK_NEEDED_ITEMS:
      return {
        ...state,
        list: [...action.payload],
      };
    case APPEND_STOCK_NEEDED_ITEMS:
      return {
        ...state,
        list: [...state.list, ...action.payload],
      };
    default:
      return state;
  }
};

export default StockNeeded;