import {SET_META, SET_PURCHASE_ORDERS, SET_FILTERS, RESET_FILTERS} from '../constants/PurchaseOrders';
import dayjs from 'dayjs';
import {DATE_FORMAT_YYYY_MM_DD} from '../../constants/DateConstant';

const defaultFilters = () => ({
  fromDate: dayjs().format(DATE_FORMAT_YYYY_MM_DD),
  toDate: dayjs().add(1,'days').format(DATE_FORMAT_YYYY_MM_DD),
  supplier: null,
  buyer: null,
  itemKeyword: "",
  priority: 'all',
  status: 'all',
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
}

const PurchaseOrders = (state = initState, action) => {
  switch (action.type) {
    case SET_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        }
      };
    case RESET_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_PURCHASE_ORDERS:
      return {
        ...state,
        list: [...action.payload],
      };
    default:
      return state;
  }
};

export default PurchaseOrders;