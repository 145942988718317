import {
  RESET_STANDING_PURCHASE_ORDERS_FILTERS,
  SET_STANDING_PURCHASE_ORDERS,
  SET_STANDING_PURCHASE_ORDERS_FILTERS,
  SET_STANDING_PURCHASE_ORDERS_META,
  UPDATE_STANDING_PURCHASE_ORDER_BY_KEY_VALUE,
  REMOVE_STANDING_PURCHASE_ORDER,
} from '../constants/StandingPurchaseOrders';

const defaultFilters = () => ({
  supplier_id: null,
});

const initState = {
  list: [],
  filters: defaultFilters(),
  meta: {
    initialized: false,
    loading: false,
  },
};

const StandingPurchaseOrders = (state = initState, action) => {
  switch (action.type) {
    case SET_STANDING_PURCHASE_ORDERS_META:
      return {
        ...state,
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_STANDING_PURCHASE_ORDERS_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };
    case RESET_STANDING_PURCHASE_ORDERS_FILTERS:
      return {
        ...state,
        filters: defaultFilters(),
      };
    case SET_STANDING_PURCHASE_ORDERS:
      return {
        ...state,
        list: [...action.payload],
      };
    case UPDATE_STANDING_PURCHASE_ORDER_BY_KEY_VALUE:
      return {
        ...state,
        list: [
          ...state.list.map(standingPurchaseOrder => {
            return standingPurchaseOrder.id === action.payload.standingPurchaseOrderId ?
                {...standingPurchaseOrder, [action.payload.key]: action.payload.val} :
                standingPurchaseOrder;
          })],
      };
    case REMOVE_STANDING_PURCHASE_ORDER:
      return {
        ...state,
        list: [...state.list.filter(standingPurchaseOrder => standingPurchaseOrder.id !== action.payload.standingPurchaseOrderId)]
      }
    default:
      return state;
  }
};

export default StandingPurchaseOrders;