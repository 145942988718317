const dev = {
  API_ENDPOINT_URL: 'http://probros-webapp.exp/api/buyers/v1',
  // API_ENDPOINT_URL: 'https://james-patrick-keegan.ap-1.sharedwithexpose.com/api/buyers/v1'
};

const prod = {
  API_ENDPOINT_URL: 'https://app.probrosprovidore.com.au/api/buyers/v1'
};

const test = {
  API_ENDPOINT_URL: 'https://pb.sydneydigitalgroup.com.au/api/buyers/v1'
};

const getEnv = () => {
	switch (process.env.NODE_ENV) {
		case 'development':
			return dev
		case 'production':
			return prod
		case 'test':
			return test
		default:
			break;
	}
}

export const env = getEnv()
