import {SET_TOGGLE_BOC_FILTER_OPTIONS_META, SET_TOGGLE_BOC_FILTER_OPTIONS} from '../constants/ToggleBOCFilterOptions';

const initState = {
  list: [],
  meta: {
    initialized: false,
    loading: false,
  },
};

const ToggleBOCFilterOptions = (state=initState, action) => {
  switch (action.type) {
    case SET_TOGGLE_BOC_FILTER_OPTIONS_META:
      return {
        list: [...state.list],
        meta: {
          ...state.meta,
          ...action.payload,
        },
      };
    case SET_TOGGLE_BOC_FILTER_OPTIONS:
      return {
        list: [...action.payload],
        meta: {...state.meta},
      };
    default:
      return state;
  }
}

export default ToggleBOCFilterOptions;