import React, {useState} from 'react'
import {connect, useSelector} from 'react-redux'
import SideNav from 'components/layout-components/SideNav'
import TopNav from 'components/layout-components/TopNav'
import Loading from 'components/shared-components/Loading'
import MobileNav from 'components/layout-components/MobileNav'
import HeaderNav from 'components/layout-components/HeaderNav'
import PageHeader from 'components/layout-components/PageHeader'
import AppViews from 'views/app-views'
import {
    Layout,
    Grid,
} from "antd"

import navigationConfig from "configs/NavigationConfig"
import {
    SIDE_NAV_WIDTH,
    SIDE_NAV_COLLAPSED_WIDTH,
    NAV_TYPE_SIDE,
    NAV_TYPE_TOP,
    DIR_RTL,
    DIR_LTR
} from 'constants/ThemeConstant'
import utils from 'utils'
import {useThemeSwitcher} from "react-css-theme-switcher"
import {Redirect} from 'react-router-dom'
import {AUTH_PREFIX_PATH} from '../../configs/AppConfig'
import {detectIsPortrait} from "../../probros/utilities"


const {Content} = Layout
const {useBreakpoint} = Grid

export const AppLayout = ({navCollapsed, navType, location, direction}) => {
    const profile = useSelector(state => state.auth.profile)
    const currentRouteInfo = utils.getRouteInfo(navigationConfig, location.pathname)
    const screens = utils.getBreakPoint(useBreakpoint())
    const isMobile = !screens.includes('lg')
    const isNavSide = navType === NAV_TYPE_SIDE
    const isNavTop = navType === NAV_TYPE_TOP

    // Orientation detector
    const [isPortrait, setIsPortrait] = useState(detectIsPortrait())
    window.addEventListener("orientationchange", () => {
        setIsPortrait(detectIsPortrait())
    })


    const getLayoutGutter = () => {
        if (isNavTop || isMobile) {
            return 0
        }
        return navCollapsed ? SIDE_NAV_COLLAPSED_WIDTH : SIDE_NAV_WIDTH
    }

    const {status} = useThemeSwitcher()

    if (status === 'loading') {
        return <Loading cover="page"/>
    }

    const getLayoutDirectionGutter = () => {
        if (direction === DIR_LTR) {
            return {paddingLeft: getLayoutGutter()}
        }
        if (direction === DIR_RTL) {
            return {paddingRight: getLayoutGutter()}
        }
        return {paddingLeft: getLayoutGutter()}
    }

    return !profile ? (<Redirect to={AUTH_PREFIX_PATH}/>) : (
        <Layout>
            <HeaderNav isMobile={isMobile}/>
            {(isNavTop && !isMobile) ? <TopNav routeInfo={currentRouteInfo}/> : null}
            <Layout className="app-container">
                {(isNavSide && !isMobile) ? <SideNav routeInfo={currentRouteInfo}/> : null}
                <Layout className="app-layout"
                        style={getLayoutDirectionGutter()}>
                    <div
                        style={{paddingLeft: '20px', paddingRight: '20px', paddingTop: isPortrait ? '50px' : '37px'}}
                        className={`${isNavTop ? 'layout-top-nav' : ''}`}
                    >
                        {/*<h1> {isPortrait ? '50px' : '37px'} </h1>*/}

                        <PageHeader display={currentRouteInfo?.breadcrumb}
                                    title={currentRouteInfo?.title}/>
                        <Content>
                            <AppViews/>
                        </Content>
                    </div>
                    {/*<Footer />*/}
                </Layout>
            </Layout>
            {isMobile && <MobileNav/>}
        </Layout>
    )
}

const mapStateToProps = ({theme}) => {
    const {navCollapsed, navType, locale} = theme
    return {navCollapsed, navType, locale}
}

export default connect(mapStateToProps)(React.memo(AppLayout))